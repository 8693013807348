<template>
  <div class="create-new-tink-connection">
    <template v-if="!isLoaded">
      <loader />
    </template>
    <template v-else>
      <b-form-group
        label-class="align-self-top pt-lg-3 pr-md-0 pr-lg-4 text-black"
        label-for="provider"
        label-cols-lg="5"
        label-cols-xl="5"
        label-align-lg="left"
        class="pt-md-4"
        :label="$t(translationPath + 'labels.select_provider')"
        :invalid-feedback="invalidProviderFeedback"
      >
        <b-form-select
          v-model="provider"
          id="provider"
          :options="providerOptions"
          :state="$v.provider.$dirty ? !$v.provider.$error : null"
          @change="providerSelected"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        label-class="align-self-top pt-lg-3 pr-md-0 pr-lg-4 text-black"
        label-for="connection-name"
        label-cols-lg="5"
        label-cols-xl="5"
        label-align-lg="left"
        class="pt-md-4"
        :label="$t(translationPath + 'labels.connection_name')"
        :invalid-feedback="invalidTitleFeedback"
      >
        <b-form-input
          id="connection-name"
          v-model="title"
          autofocus
          :state="$v.title.$dirty ? !$v.title.$error : null"
        />
      </b-form-group>
      <b-row no-gutters v-if="showSaveButton">
        <b-col class="text-right">
          <b-button variant="primary" @click="onSave">{{ $t(translationPath + 'labels.create_connection') }}</b-button>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
/**
 * Component which creates new tink connection.
 * Can be used in different wizards.
 * It provides form with validation to create new connection. You can hide "create"
 * button if you want to use "parent component button" for performing creation.
 * It is useful in wizards, where you can setup "next" button of wizard to
 * perform creating connection.
 * onSave() method is responsible for creating connection. It starts from form validation
 * and if form is ok it sends request to backend to store new empty (not authenticated)
 * connection. This method returns a Promise, so if you call this method from external component
 * you can use .then() and .catch() promise methods to perform code after api request is finished.
 * Also in emits 'success' event when connection is created, and 'fail' event on error.
 */

import axios from 'axios'
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

/**
 * Validation rule. Check if user can add one more connection for selected provider.
 * We restrict to create a lot of connections per each provider. We allow only 2
 * connections per provider.
 */
const isNewConnectionAllowed = getter => function () {
  if (!this.provider) {
    return true
  }

  let isAllowed = true
  let cntConnections = 0
  for (const i in this.connections) {
    if (this.connections[i].tink_provider_id === this.provider.id) {
      cntConnections++
    }

    if (cntConnections >= 2) {
      isAllowed = false
      break
    }
  }

  return isAllowed
}

export default {
  name: 'CreateTinkConnection',
  components: { Loader },
  props: {
    showSaveButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      translationPath: 'common.create_new_tink_connection.',
      useTestProviders: process.env.VUE_APP_USE_TINK_TEST_PROVIDERS === '1',
      isLoaded: false,
      title: '',
      provider: null,
      providers: []
    }
  },
  validations: {
    title: { required, minLength: minLength(2), maxLength: maxLength(255) },
    provider: { required, isNewConnectionAllowed: isNewConnectionAllowed() },
    form: ['provider', 'title']
  },
  computed: {
    ...mapState('user', ['currentUser']),
    providerOptions () {
      const opts = []
      this.providers.map(provider => {
        opts.push({
          text: this.$t(provider.translation_string_id),
          value: provider
        })
      })

      opts.sort((a, b) => {
        return a.text > b.text ? 1 : -1
      })
      return opts
    },
    invalidTitleFeedback () {
      if (this.$v.title.required === false) return this.$t(this.translationPath + 'errors.connection_name.required')
      if (this.$v.title.minLength === false) return this.$t(this.translationPath + 'errors.connection_name.min_length')
      if (this.$v.title.maxLength === false) return this.$t(this.translationPath + 'errors.connection_name.max_length')
      return ''
    },
    invalidProviderFeedback () {
      if (this.$v.provider.required === false) return this.$t(this.translationPath + 'errors.provider.required')
      if (this.$v.provider.isNewConnectionAllowed === false) return this.$t(this.translationPath + 'errors.provider.connections_not_allowed')
      return ''
    }
  },
  methods: {
    async loadConnections () {
      this.connections = []

      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/tink/connections`)
          .then(response => {
            this.connections = response.data.data
            this.connections.map(i => {
              const tmp = i.name.match(/#.+#/)
              if (tmp !== null) {
                i.name = i.name.replace(/#.+#/, this.$t(tmp[0].replaceAll('#', '')))
              }
            })
            resolve(this.connections)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    async loadProviders () {
      this.providers = []

      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/tink/providers?is_test=${Number(this.currentUser.tink_app !== 'production')}`)
          .then(response => {
            this.providers = response.data.data
            resolve(this.providers)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    providerSelected () {
      this.title = this.currentUser.firstname + ' - ' + this.$t(this.provider.translation_string_id)
    },
    /**
     * Sends request to backend API to store new empty (not authenticated) connection.
     */
    async onSave () {
      return new Promise((resolve, reject) => {
        this.$v.form.$touch()
        if (!this.$v.form.$invalid) {
          const postData = {
            tink_provider_id: this.provider.id,
            name: this.title
          }
          axios.post(`${process.env.VUE_APP_ROOT_API}/tink/save-empty-connection`, postData)
            .then(response => {
              const data = { response: response, provider: this.provider }
              this.$emit('success', data)
              resolve(data)
            })
            .catch(err => {
              console.error(err)
              this.emit('fail')
              reject(err)
            })
        }
      })
    }
  },
  created () {
    Promise.all([
      this.loadProviders(),
      this.loadConnections()
    ])
      .then(() => {
        this.isLoaded = true
      })
  }
}
</script>
